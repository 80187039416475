<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">

<!--            //Code-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="code"
                  rules="required"
                  name="The Code"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="'Coupon Code'"
                            name="name"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
<!--            //Type-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="type"
                  rules="required"
                  name="The Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="type"
                    size="large"
                    filterable
                    clearable
                    :placeholder="'Type'"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Type'"
                    :list="builderData.typeList"
                    :listItemLabel="'name'"
                    :listItemValue="'value'"
                    v-model="formData.type">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //Value-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="value"
                  rules="required"
                  name="The Value"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="'Value'"
                            name="value"
                            fou
                            v-model="formData.value">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

<!--            //Use number-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="number_use"
                  rules="required"
                  name="The Use Number"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="'Use Number'"
                            name="number_use"
                            fou
                            v-model="formData.number_use">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <!--            //Use number per account-->
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-md-12">
                <ValidationProvider
                  vid="number_per_account"
                  rules=""
                  name="The Use Number Per Account"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="' Use Number Per Account'"
                            name="number_per_account"
                            fou
                            v-model="formData.number_per_account">
                  </fg-input>
                  <i style="font-size: 11px;color: #9A9A9A;position: absolute;bottom: 0;"> Leave empty if you do not want to restrict it per account </i>

                </ValidationProvider>
              </div>
            </div>

<!-- End date-->
            <div class="row">
              <div class="col-md-12">
                <label class="card-label">{{ 'Expiry Date' }}</label>
                <fg-input v-model="formData.expiry_date"
                          value-format="YYYY-MM-DD"
                          type="date"
                          placeholder="Chose coupon expire date"
                >

                </fg-input>
              </div>
            </div>

<!--            //IS ACTIVE-->
            <div class="form-group pt-4">
              <label>{{ 'Is Active' }}</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ 'Submit' }}
            </l-button>
            <l-button @click="$router.push('/coupons/list')" type="danger" wide>{{ 'Cancel' }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import LSwitch from "../../components/Switch";
import PaginatedSelect from "@/components/paginatedSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    LSwitch,
    FgSelect,
    PaginatedSelect
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        type: "",
        value: "",
        number_use: "",
        expiry_date: "",
        number_per_account : 0,
        is_active: true,
      },
      builderData: {
        typeList: [],
      }
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });

    let data = {};
    this.axios.post("coupons/builder", data).then((response) => {
      
      this.builderData.typeList = response.data.couponTypes;
      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Coupon";
        this.getCoupon();
      } else {
        this.editMode = false;
        this.formTitle = "Add Coupon";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })


  },


  methods: {
    generateSomIdent() {
      if (!this.editMode) {
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }
        this.formData.ident = newUrl;
      }
    },
    getCoupon() {
      let data = {
        'id': this.id,
      };
      this.axios.post("coupons/get", data).then((response) => {
        this.formData = response.data;
        this.paginatedCompKey++;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Coupon Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("coupons/update/" + this.id, this.formData);
        successMessage = "Coupon Updated Successfully";
      } else {
        request = this.axios.post("coupons/create", this.formData);
        successMessage = "Coupon Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/coupons/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
}
</script>

<style>
</style>
